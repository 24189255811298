:root {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-size: 100%;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  background-color: #f6f6f6;
}

h2 {
  font-size: 3.63rem;
}

h4 {
  font-size: 1.25rem;
}

h6 {
  font-size: 0.75rem;
  margin: 0;
}

/**
font-size: 16px = 1rem
border-radius das abas: 1.75rem
border-radius das tabelas: 0.5rem
 */

