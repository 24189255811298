.loading-ui {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.5;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2000;
}

.loading-ui .loading-ring {
  display: inline-block;
  position: absolute;
  height: 60px;
  width: 60px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-ui .loading-ring div {
  box-sizing: border-box;
  display: block;
  opacity: 1;
  position: absolute;
  height: 60px;
  width: 60px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loading-ui .loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-ui .loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-ui .loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) {
  .loading-ui .loading-ring div {
    height: 60px;
    width: 60px;
  }
}
