.cabecalho {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
}

.cabecalho-logo {
  height: 46px;
}

.cabecalho-menu {
  display: flex;
  gap: 56px;
}

.menu-item {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 15px;
}

.conteudo {
  margin-bottom: 50px;
}

.capa-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #497dc8;
  padding-top: 60px;
}

.capa-frase {
  display: flex;
  flex-direction: column;
  padding-left: 22px;
}

.capa-titulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 56px;
}

.capa-subtitulo {
  font-family: 'Playfair Display', serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 56px;
}

.botao {
  background-color: #fca311;
  box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
  width: 311px;
  height: 58px;
  margin-top: 100px;
  border: none;
  border-radius: 29px;
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;

}

.active, .botao:hover {
  background-color: #ffc66a;
  color: #14213d;
}

.capa-imagem {
  height: 660px;
}

.sessao1 {
  background-color: #14213d;
}

.sessao1-faixa {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
}

.icone-seta {
  height: 16px;
}

.frase-faixa {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 400;
  font-size: 30px;
}

.sessao2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sessao2-frase {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 35px;
  text-align: center;
  margin-top: 60px;
}

.sessao2-cards {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 65px;
  margin-bottom: 30px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 40px;
  background-color: #497dc8;
  box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 280px;
}

.icone-card {
  height: 40px;
}

.frase-card {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 400;
  font-size: 21px;
  text-align: center;
}

.sessao3 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 65px;
}

.sessao3-texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #14213d;
  width: 900px;
  border-radius: 0 50px 50px 0;
  padding: 60px;
}

.sessao3-imagem {
  position: absolute;
  left: 260px;
  height: 857px;
}

.sessao3-titulo {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 400;
  font-size: 56px;
}

.sessao3-subtitulo {
  font-family: 'Playfair Display', serif;
  color: #fca311;
  font-weight: 700;
  font-size: 56px;
  margin-bottom: 60px;
}

.sessao3-itens {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}


.sessao4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 65px;
}

.sessao4-titulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.sessao4-circulos {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-top: 40px;
}

.circulo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fca311;
  box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
  border-radius: 100px;
  width: 193px;
  height: 193px;
}

.sessao4-subtitulo {
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  margin-bottom: 135px;
}

.titulo1-circulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 20px;
  margin-right: 80px;
  margin-left: 130px;
}

.titulo2-circulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 20px;
  margin-right: 120px;
  margin-left: 160px;
}

.titulo3-circulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 20px;
  margin-right: 110px;
  margin-left: 100px;
}

.sessao5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sessao5-texto {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 5px;
  margin-left: 55px;
}

.sessao5-titulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 42px;
  margin-bottom: 35px;
}

.sessao5-subtitulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 30px;
}

.sessao5-paragrafo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 50px;
}

.sessao5-imagem {
  background-color: #ffc66a;
  border-radius: 50px 0 0 50px;
  height: 560px;
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 50px;
  margin-left: 65px;
}

.sessao6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.sessao6-titulo {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 40px;
  margin-top: 65px;
  text-align: center;
}

.accordion {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  margin-left: 19px;
  margin-right: 19px;
  border-radius: 50px;
  background-color: #e5e5e5;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.topicTitle {
  margin-bottom: 30px;
}

.topicTitle h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.active, .accordion:hover {
  background-color: #14213d;
  color: #ffffff;
}

.accordion:after {
  content: '\FE40'; /* Unicode character for "plus" sign (﹀) */
  font-size: 15px;
  font-weight: 700;
  text-align-last: center;
  float: right;
  margin-left: 5px;
  margin-right: 10px;
  padding-top: 4px;
}

.active:after {
  content: "\FE3F"; /* Unicode character for "minus" sign (︿) */
}

.panel {
  font-family: 'Roboto', sans-serif;
  text-align: justify;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 20px;
  display: none;
  overflow: hidden;
}

.rodape {
  display: flex;
  flex-direction: row;
  background-color: #e5e4e4;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
}

.rodape-logotipo {
  height: 50px;
}

.rodape-texto {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rodape-titulo {
  font-family: 'Roboto', sans-serif;
  color: #ad8e3e;
  font-weight: 700;
  font-size: 14px;
}

.rodape-telefone {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 14px;
}

.rodape-email {
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 14px;
}

.rodape-menu {
  display: flex;
  flex-direction: row;
  gap: 80px;
  font-family: 'Roboto', sans-serif;
  color: #14213d;
  font-weight: 400;
  font-size: 14px;
}

.rodape-menu-ordenacao {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rodape-redes-sociais {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media screen and (min-width: 1360px) {

  .capa-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #497dc8;
    padding-top: 0px;
  }

  .capa-frase {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
  }

  .capa-titulo {
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 400;
    font-size: 46px;
  }

  .capa-subtitulo {
    font-family: 'Playfair Display', serif;
    color: #ffffff;
    font-weight: 700;
    font-size: 46px;
  }

  .botao {
    background-color: #fca311;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
    width: 311px;
    height: 58px;
    margin-top: 100px;
    border: none;
    border-radius: 29px;
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
  }

  .active, .botao:hover {
    background-color: #ffc66a;
    color: #14213d;
  }

  .capa-imagem {
    height: 500px;
  }

  .sessao1 {
    background-color: #14213d;
  }

  .sessao1-faixa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
  }

  .icone-seta {
    height: 16px;
  }

  .frase-faixa {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 400;
    font-size: 30px;
  }
}

@media screen and (min-width: 1400px) {

  .capa-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #497dc8;
    padding-top: 100px;
  }

  .capa-frase {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
  }

  .capa-titulo {
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 400;
    font-size: 56px;
  }

  .capa-subtitulo {
    font-family: 'Playfair Display', serif;
    color: #ffffff;
    font-weight: 700;
    font-size: 56px;
  }

  .botao {
    background-color: #fca311;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
    width: 311px;
    height: 58px;
    margin-top: 100px;
    border: none;
    border-radius: 29px;
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
  }

  .active, .botao:hover {
    background-color: #ffc66a;
    color: #14213d;
  }

  .capa-imagem {
    height: 620px;
  }

  .sessao1 {
    background-color: #14213d;
  }

  .sessao1-faixa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px;
  }

  .icone-seta {
    height: 16px;
  }

  .frase-faixa {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 400;
    font-size: 30px;
  }
}

@media screen and (min-width: 1440px) {

  .capa-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #497dc8;
    padding-top: 0px;
  }

  .capa-frase {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
  }

  .capa-titulo {
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 400;
    font-size: 46px;
  }

  .capa-subtitulo {
    font-family: 'Playfair Display', serif;
    color: #ffffff;
    font-weight: 700;
    font-size: 46px;
  }

  .botao {
    background-color: #fca311;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
    width: 311px;
    height: 58px;
    margin-top: 100px;
    border: none;
    border-radius: 29px;
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
  }

  .active, .botao:hover {
    background-color: #ffc66a;
    color: #14213d;
  }

  .capa-imagem {
    height: 620px;
  }

  .sessao1 {
    background-color: #14213d;
  }

  .sessao1-faixa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
  }

  .icone-seta {
    height: 16px;
  }

  .frase-faixa {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 400;
    font-size: 30px;
  }
}

@media screen and (min-width: 1680px) {

  .capa-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #497dc8;
    padding-top: 0px;
  }

  .capa-frase {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
  }

  .capa-titulo {
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 400;
    font-size: 46px;
  }

  .capa-subtitulo {
    font-family: 'Playfair Display', serif;
    color: #ffffff;
    font-weight: 700;
    font-size: 46px;
  }

  .botao {
    background-color: #fca311;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
    width: 311px;
    height: 58px;
    margin-top: 100px;
    border: none;
    border-radius: 29px;
    font-family: 'Roboto', sans-serif;
    color: #14213d;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
  }

  .active, .botao:hover {
    background-color: #ffc66a;
    color: #14213d;
  }

  .capa-imagem {
    height: 770px;
  }

  .sessao1 {
    background-color: #14213d;
  }

  .sessao1-faixa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
  }

  .icone-seta {
    height: 16px;
  }

  .frase-faixa {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 400;
    font-size: 30px;
  }
}
