.login-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    position: absolute;
    top: 30%;
    width: 350px;
    padding: 40px 30px;
    background: #eee;
    border-radius: 4px;
}

.login-logo {
    height: 50px;
    margin-bottom: 40px;
}


.form-group {
    width: 100%;
    margin-bottom: 15px;
}

.form-control {
    height: 50px;
    width: 100%;
    border: none;
    padding: 5px 7px 5px 15px;
    background: #fff;
    color: #666;
    border: 2px solid #ddd;
    border-radius: 4px;
}

.form-group .fa {
    position: absolute;
    right: 15px;
    top: 17px;
    color: #999;
}

.log-btn {
    background: #ad8e3e;
    display: inline-block;
    width: 100%;
    height: 50px;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.link {
    text-decoration: none;
    color: #c6c6c6;
    float: right;
    font-size: 12px;
    margin-bottom: 15px;
}

.panel {
    font-family: 'Roboto', sans-serif;
    color: #ff0000;
    font-weight: 400;
    font-size: 16px;
}
